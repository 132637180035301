(function ($) {
    $(document).ready(function () {
        var mySwiper = new Swiper('.swiper-gallery-container', {
            // Navigation arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 4,
            spaceBetween: 30,

            breakpoints: {

                1024: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                425: {
                    slidesPerView: 1,
                },
            }
        });
        var imageSwiper = new Swiper('.swiper-image-container', {
            // Navigation arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 1,
            spaceBetween: 30
        });
        var textSwiper = new Swiper('.swiper-text-container', {
            // Navigation arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            autoplay: 7000,
            slidesPerView: 1,
            spaceBetween: 30
        });
        $("img").unveil();
    });
    $(window).resize(function () {
        $("img").unveil();
    });

}(jQuery));
